





























































































































































































































































































































































































































































































































































































































































































































































































































































































.download_template_wrapper {
  display: flex;
  justify-content: center;
  .download_template {
    background-color: #87cb16;
    padding: 20px;
    border-radius: 9px;
    transition: 0.3s;
    font-size: 17px;
    min-width: 150px;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}
.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
.school_teachers_page_wrapper {
  .icons_table_wrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 5px 0;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      &.edit {
        background-color: #409eff;
        color: #fff;
      }
      &.remove {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.tabs_heading {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 3px solid #e4e4e4;
  .our_buttons {
    border: none;
    background: none;
    color: #777;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      content: "";
      background: #409eff;
      width: 100%;
      height: 3px;
      bottom: -12px;
      display: none;
    }
    &.is_active {
      &::before {
        display: block;
      }
    }
  }
}
.upload_excel_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
}
::v-deep .el-upload {
  background-color: #87cb16;
  padding: 20px;
  border-radius: 9px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
